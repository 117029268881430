import React, { useState, useRef, useEffect } from 'react';
import { Project, Profile, ProjectPermission } from '../types';
import { Plus, X, Save, Trash2, Upload, ArrowLeft, LogOut, Users, Shield } from 'lucide-react';
import { supabase, signOut } from '../lib/supabase';
import { uploadToCloudinary } from '../lib/cloudinary';
import toast from 'react-hot-toast';
import { z } from 'zod';
import { UserManagement } from './UserManagement';

interface AdminPanelProps {
  projects: Project[];
  onUpdateProjects: () => void;
  onExit: () => void;
}

const projectSchema = z.object({
  title: z.string().min(1, 'Başlık zorunludur'),
  description: z.string().min(1, 'Açıklama zorunludur'),
  imageUrl: z.string().url('Geçerli bir görsel URL\'si giriniz'),
  link: z.string().url('Geçerli bir proje linki giriniz'),
  tags: z.array(z.string()).optional()
});

export function AdminPanel({ projects, onUpdateProjects, onExit }: AdminPanelProps) {
  const [editingProject, setEditingProject] = useState<Project | null>(null);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showUserManagement, setShowUserManagement] = useState(false);
  const [userProfile, setUserProfile] = useState<Profile | null>(null);
  const [userProjects, setUserProjects] = useState<Project[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  useEffect(() => {
    if (userProfile) {
      fetchUserProjects();
    }
  }, [userProfile]);

  const fetchUserProfile = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) {
        throw new Error('Kullanıcı bulunamadı');
      }

      const { data: profileData, error: profileError } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();

      if (profileError) {
        throw profileError;
      }

      setUserProfile({
        id: profileData.id,
        username: profileData.username,
        fullName: profileData.full_name,
        avatarUrl: profileData.avatar_url,
        isApproved: profileData.is_approved,
        role: profileData.role as 'admin' | 'user',
        createdAt: profileData.created_at
      });

      setIsAdmin(profileData.role === 'admin');
    } catch (err) {
      console.error('Error fetching user profile:', err);
      toast.error('Kullanıcı profili yüklenirken bir hata oluştu');
    }
  };

  const fetchUserProjects = async () => {
    try {
      let query = supabase.from('projects').select('*');
      
      // If not admin, only show user's projects
      if (userProfile && userProfile.role !== 'admin') {
        query = query.eq('owner_id', userProfile.id);
      }

      const { data, error } = await query.order('created_at', { ascending: false });

      if (error) {
        throw error;
      }

      const transformedProjects = data.map(project => ({
        id: project.id,
        title: project.title,
        description: project.description,
        imageUrl: project.image_url,
        link: project.link,
        tags: project.tags || [],
        ownerId: project.owner_id
      }));

      setUserProjects(transformedProjects);
    } catch (err) {
      console.error('Error fetching user projects:', err);
      toast.error('Projeler yüklenirken bir hata oluştu');
    }
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) return;
    
    try {
      setUploading(true);
      const file = e.target.files[0];
      const imageUrl = await uploadToCloudinary(file);
      
      if (editingProject) {
        setEditingProject({
          ...editingProject,
          imageUrl
        });
      }
    } catch (err) {
      console.error('Error in handleImageUpload:', err);
    } finally {
      setUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const validateProject = (project: Project) => {
    try {
      projectSchema.parse(project);
      return true;
    } catch (err) {
      if (err instanceof z.ZodError) {
        toast.error(err.errors[0].message);
      }
      return false;
    }
  };

  const handleSave = async (project: Project) => {
    if (!validateProject(project)) return;

    try {
      const { data: session } = await supabase.auth.getSession();
      
      if (!session?.session?.access_token) {
        throw new Error('Oturum bulunamadı. Lütfen tekrar giriş yapın.');
      }

      const toastId = toast.loading(isAddingNew ? 'Proje ekleniyor...' : 'Proje güncelleniyor...');

      if (isAddingNew) {
        const { error: insertError } = await supabase
          .from('projects')
          .insert([{
            title: project.title,
            description: project.description,
            image_url: project.imageUrl,
            link: project.link,
            tags: project.tags || [],
            owner_id: userProfile?.id
          }]);

        if (insertError) throw insertError;
        toast.success('Proje başarıyla eklendi', { id: toastId });
      } else {
        // Check if user has permission to edit this project
        if (!isAdmin && project.ownerId !== userProfile?.id) {
          throw new Error('Bu projeyi düzenleme yetkiniz yok');
        }

        const { error: updateError } = await supabase
          .from('projects')
          .update({
            title: project.title,
            description: project.description,
            image_url: project.imageUrl,
            link: project.link,
            tags: project.tags || [],
            updated_at: new Date().toISOString()
          })
          .eq('id', project.id);

        if (updateError) throw updateError;
        toast.success('Proje başarıyla güncellendi', { id: toastId });
      }

      onUpdateProjects();
      fetchUserProjects();
      setEditingProject(null);
      setIsAddingNew(false);
    } catch (err) {
      console.error('Error saving project:', err);
      toast.error(err instanceof Error ? err.message : 'Proje kaydedilirken bir hata oluştu.');
    }
  };

  const handleDelete = async (id: string) => {
    if (confirm('Bu projeyi silmek istediğinizden emin misiniz?')) {
      try {
        const toastId = toast.loading('Proje siliniyor...');
        
        // Check if user has permission to delete this project
        const project = userProjects.find(p => p.id === id);
        if (!isAdmin && project?.ownerId !== userProfile?.id) {
          throw new Error('Bu projeyi silme yetkiniz yok');
        }

        const { error: deleteError } = await supabase
          .from('projects')
          .delete()
          .eq('id', id);

        if (deleteError) throw deleteError;
        
        toast.success('Proje başarıyla silindi', { id: toastId });
        onUpdateProjects();
        fetchUserProjects();
      } catch (err) {
        console.error('Error deleting project:', err);
        toast.error(err instanceof Error ? err.message : 'Proje silinirken bir hata oluştu');
      }
    }
  };

  const handleLogout = async () => {
    if (confirm('Çıkış yapmak istediğinizden emin misiniz?')) {
      await signOut();
      onExit();
    }
  };

  if (showUserManagement) {
    return <UserManagement onBack={() => setShowUserManagement(false)} />;
  }

  return (
    <div className="min-h-screen bg-gray-950 p-6">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <div className="flex items-center space-x-4">
            <button
              onClick={onExit}
              className="flex items-center space-x-2 text-gray-400 hover:text-gray-200 transition-colors"
            >
              <ArrowLeft className="w-5 h-5" />
              <span>Siteye Dön</span>
            </button>
            <h2 className="text-2xl font-bold text-gray-100">Proje Yönetimi</h2>
            {userProfile && (
              <div className="ml-4 text-gray-400">
                <span className="mr-2">Kullanıcı:</span>
                <span className="text-gray-200">{userProfile.fullName || userProfile.username}</span>
                <span className="ml-2 px-2 py-0.5 text-xs rounded-full bg-blue-900 text-blue-200">
                  {userProfile.role === 'admin' ? 'Admin' : 'Kullanıcı'}
                </span>
              </div>
            )}
          </div>
          <div className="flex items-center gap-3">
            {isAdmin && (
              <button
                onClick={() => setShowUserManagement(true)}
                className="flex items-center gap-2 bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-lg transition-colors"
              >
                <Users className="w-4 h-4" />
                Kullanıcı Yönetimi
              </button>
            )}
            <button
              onClick={() => {
                setEditingProject({
                  id: '',
                  title: '',
                  description: '',
                  imageUrl: '',
                  link: '',
                  tags: []
                });
                setIsAddingNew(true);
              }}
              className="flex items-center gap-2 bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-lg transition-colors"
            >
              <Plus className="w-4 h-4" />
              Yeni Proje
            </button>
            <button
              onClick={handleLogout}
              className="flex items-center gap-2 bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-lg transition-colors"
            >
              <LogOut className="w-4 h-4" />
              Çıkış Yap
            </button>
          </div>
        </div>

        {(editingProject || isAddingNew) && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
            <div className="bg-gray-900 p-6 rounded-xl w-full max-w-2xl">
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-xl font-semibold text-gray-100">
                  {isAddingNew ? 'Yeni Proje Ekle' : 'Projeyi Düzenle'}
                </h3>
                <button
                  onClick={() => {
                    setEditingProject(null);
                    setIsAddingNew(false);
                  }}
                  className="text-gray-400 hover:text-gray-200"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">Başlık</label>
                  <input
                    type="text"
                    value={editingProject.title}
                    onChange={(e) => setEditingProject({ ...editingProject, title: e.target.value })}
                    className="w-full bg-gray-800 border border-gray-700 rounded-lg px-4 py-2 text-gray-100"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">Açıklama</label>
                  <textarea
                    value={editingProject.description}
                    onChange={(e) => setEditingProject({ ...editingProject, description: e.target.value })}
                    className="w-full bg-gray-800 border border-gray-700 rounded-lg px-4 py-2 text-gray-100 h-24"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">Görsel</label>
                  <div className="flex items-center space-x-4">
                    <input
                      type="text"
                      value={editingProject.imageUrl}
                      onChange={(e) => setEditingProject({ ...editingProject, imageUrl: e.target.value })}
                      className="flex-1 bg-gray-800 border border-gray-700 rounded-lg px-4 py-2 text-gray-100"
                      placeholder="Görsel URL'si veya yükle"
                      required
                    />
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleImageUpload}
                      accept="image/*"
                      className="hidden"
                    />
                    <button
                      onClick={() => fileInputRef.current?.click()}
                      disabled={uploading}
                      className="flex items-center gap-2 bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg transition-colors disabled:opacity-50"
                    >
                      <Upload className="w-4 h-4" />
                      {uploading ? 'Yükleniyor...' : 'Yükle'}
                    </button>
                  </div>
                  {editingProject.imageUrl && (
                    <div className="mt-2">
                      <img
                        src={editingProject.imageUrl}
                        alt="Preview"
                        className="h-32 object-cover rounded-lg"
                        onError={(e) => {
                          e.currentTarget.src = 'https://via.placeholder.com/300x200?text=Resim+Yüklenemedi';
                        }}
                      />
                    </div>
                  )}
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">Proje Linki</label>
                  <input
                    type="url"
                    value={editingProject.link}
                    onChange={(e) => setEditingProject({ ...editingProject, link: e.target.value })}
                    className="w-full bg-gray-800 border border-gray-700 rounded-lg px-4 py-2 text-gray-100"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">Etiketler (virgülle ayırın)</label>
                  <input
                    type="text"
                    value={editingProject.tags?.join(', ') || ''}
                    onChange={(e) => setEditingProject({
                      ...editingProject,
                      tags: e.target.value.split(',').map(tag => tag.trim()).filter(Boolean)
                    })}
                    className="w-full bg-gray-800 border border-gray-700 rounded-lg px-4 py-2 text-gray-100"
                  />
                </div>
                <div className="flex justify-end gap-4 mt-6">
                  <button
                    onClick={() => {
                      setEditingProject(null);
                      setIsAddingNew(false);
                    }}
                    className="px-4 py-2 text-gray-300 hover:text-gray-100 transition-colors"
                  >
                    İptal
                  </button>
                  <button
                    onClick={() => handleSave(editingProject)}
                    className="flex items-center gap-2 bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg"
                  >
                    <Save className="w-4 h-4" />
                    Kaydet
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="bg-gray-900 rounded-xl border border-gray-800 overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-800">
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Başlık</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Açıklama</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Link</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Etiketler</th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-300 uppercase tracking-wider">İşlemler</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-800">
                {userProjects.map((project) => (
                  <tr key={project.id} className="hover:bg-gray-800/50">
                    <td className="px-6 py-4 whitespace-nowrap text-gray-100">{project.title}</td>
                    <td className="px-6 py-4 text-gray-300">{project.description.slice(0, 50)}...</td>
                    <td className="px-6 py-4 text-gray-300">{project.link}</td>
                    <td className="px-6 py-4">
                      <div className="flex flex-wrap gap-2">
                        {project.tags?.map((tag) => (
                          <span key={tag} className="px-2 py-1 text-xs bg-gray-800 text-gray-300 rounded-full">
                            {tag}
                          </span>
                        ))}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-right">
                      <div className="flex justify-end gap-2">
                        {(isAdmin || project.ownerId === userProfile?.id) && (
                          <>
                            <button
                              onClick={() => {
                                setEditingProject(project);
                                setIsAddingNew(false);
                              }}
                              className="text-blue-400 hover:text-blue-300"
                            >
                              Düzenle
                            </button>
                            <button
                              onClick={() => handleDelete(project.id)}
                              className="text-red-400 hover:text-red-300"
                            >
                              <Trash2 className="w-4 h-4" />
                            </button>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}