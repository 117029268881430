import toast from 'react-hot-toast';

const CLOUD_NAME = 'appstorage';
const API_KEY = '137669481269684';
const API_SECRET = 'H7G4Komec_gzgDouOzV0aAS0x_c';
const UPLOAD_PRESET = 'ml_default';

export async function uploadToCloudinary(file: File): Promise<string> {
  try {
    // Validate file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      throw new Error('Dosya boyutu 5MB\'dan küçük olmalıdır');
    }

    // Validate file type
    if (!['image/jpeg', 'image/png', 'image/webp'].includes(file.type)) {
      throw new Error('Sadece JPEG, PNG ve WebP formatları desteklenmektedir');
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', UPLOAD_PRESET);
    formData.append('api_key', API_KEY);

    toast.loading('Görsel yükleniyor...', { id: 'upload' });

    const response = await fetch(`https://api.cloudinary.com/v1_1/${CLOUD_NAME}/image/upload`, {
      method: 'POST',
      body: formData
    });

    if (!response.ok) {
      throw new Error('Görsel yüklenirken bir hata oluştu');
    }

    const data = await response.json();
    toast.success('Görsel başarıyla yüklendi', { id: 'upload' });
    
    return data.secure_url;
  } catch (error) {
    console.error('Error uploading to Cloudinary:', error);
    toast.error(error instanceof Error ? error.message : 'Görsel yüklenirken bir hata oluştu', { id: 'upload' });
    throw error;
  }
}