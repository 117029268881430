import React, { useEffect, useState } from 'react';
import { GalleryVerticalEnd, Search, Settings, LogIn, UserPlus, LogOut } from 'lucide-react';
import { ProjectCard } from './components/ProjectCard';
import { AdminLogin } from './components/AdminLogin';
import { AdminPanel } from './components/AdminPanel';
import { UserRegistration } from './components/UserRegistration';
import { supabase, signOut, getUserProfile } from './lib/supabase';
import type { Project, Profile } from './types';
import { Toaster } from 'react-hot-toast';
import { motion } from 'framer-motion';

function App() {
  const [searchTerm, setSearchTerm] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [userProfile, setUserProfile] = useState<Profile | null>(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setIsAuthenticated(!!session);
      if (session) {
        fetchUserProfile();
      }
    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setIsAuthenticated(!!session);
      if (session) {
        fetchUserProfile();
      } else {
        setUserProfile(null);
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    fetchProjects();
  }, [userProfile]);

  async function fetchUserProfile() {
    const profile = await getUserProfile();
    setUserProfile(profile);
  }

  async function fetchProjects() {
    try {
      setLoading(true);
      setError(null);
      
      let query = supabase.from('projects').select('*');
      
      // If user is not admin, only show their projects
      if (userProfile && userProfile.role !== 'admin') {
        query = query.eq('owner_id', userProfile.id);
      }
      
      const { data, error } = await query.order('created_at', { ascending: false });

      if (error) {
        throw error;
      }

      const transformedProjects: Project[] = data.map(project => ({
        id: project.id,
        title: project.title,
        description: project.description,
        imageUrl: project.image_url,
        link: project.link,
        tags: project.tags || [],
        ownerId: project.owner_id
      }));

      setProjects(transformedProjects);
    } catch (err) {
      console.error('Error fetching projects:', err);
      setError('Projeler yüklenirken bir hata oluştu. Lütfen sayfayı yenileyin.');
    } finally {
      setLoading(false);
    }
  }

  const handleLogout = async () => {
    await signOut();
    setIsAuthenticated(false);
    setUserProfile(null);
  };

  const filteredProjects = projects.filter(project =>
    project.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    project.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    project.tags?.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  if (showLoginForm) {
    return (
      <>
        <AdminLogin 
          onLogin={() => {
            setIsAuthenticated(true);
            setShowLoginForm(false);
          }}
          onCancel={() => setShowLoginForm(false)}
        />
        <Toaster position="top-right" />
      </>
    );
  }

  if (showRegistrationForm) {
    return (
      <>
        <UserRegistration 
          onSuccess={() => setShowRegistrationForm(false)}
          onCancel={() => setShowRegistrationForm(false)}
        />
        <Toaster position="top-right" />
      </>
    );
  }

  if (isEditing) {
    return (
      <>
        <AdminPanel 
          projects={projects} 
          onUpdateProjects={fetchProjects}
          onExit={() => setIsEditing(false)}
        />
        <Toaster position="top-right" />
      </>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-dark-950 to-dark-900">
      {/* Header */}
      <header className="sticky top-0 z-50 backdrop-blur-xl bg-dark-900/80 border-b border-dark-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-4 md:space-y-0">
            <motion.div 
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              className="flex items-center space-x-3"
            >
              <GalleryVerticalEnd className="w-8 h-8 text-primary-400" />
              <h1 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary-400 to-primary-300">
                AppStorage
              </h1>
            </motion.div>
            <div className="flex items-center space-x-4">
              <div className="relative flex-1 md:w-64">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Search className="h-5 w-5 text-dark-400" />
                </div>
                <input
                  type="text"
                  placeholder="Projelerde ara..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="block w-full pl-10 pr-4 py-2 bg-dark-800 border border-dark-700 rounded-lg text-white placeholder-dark-400 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent transition-all"
                />
              </div>
              {isAuthenticated ? (
                <div className="flex items-center space-x-3">
                  {userProfile && userProfile.isApproved && (
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => setIsEditing(true)}
                      className="flex items-center space-x-2 bg-primary-600 hover:bg-primary-700 text-white px-4 py-2 rounded-lg transition-colors"
                    >
                      <Settings className="w-4 h-4" />
                      <span>Düzenle</span>
                    </motion.button>
                  )}
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleLogout}
                    className="flex items-center space-x-2 bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-lg transition-colors"
                  >
                    <LogOut className="w-4 h-4" />
                    <span>Çıkış</span>
                  </motion.button>
                </div>
              ) : (
                <div className="flex items-center space-x-3">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setShowLoginForm(true)}
                    className="flex items-center space-x-2 bg-primary-600 hover:bg-primary-700 text-white px-4 py-2 rounded-lg transition-colors"
                  >
                    <LogIn className="w-4 h-4" />
                    <span>Giriş</span>
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setShowRegistrationForm(true)}
                    className="flex items-center space-x-2 bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-lg transition-colors"
                  >
                    <UserPlus className="w-4 h-4" />
                    <span>Kayıt Ol</span>
                  </motion.button>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      {/* User Status Banner */}
      {isAuthenticated && userProfile && !userProfile.isApproved && (
        <div className="bg-yellow-600/10 border-y border-yellow-600/20 text-yellow-200 px-4 py-2 text-center">
          <p>Hesabınız henüz onaylanmadı. Admin onayı bekleniyor.</p>
        </div>
      )}

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {loading ? (
          <div className="text-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-500 mx-auto"></div>
            <p className="text-dark-300 text-lg mt-4">Projeler yükleniyor...</p>
          </div>
        ) : error ? (
          <div className="text-center py-12">
            <p className="text-red-400 text-lg">{error}</p>
          </div>
        ) : (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredProjects.map((project, index) => (
                <motion.div
                  key={project.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <ProjectCard project={project} />
                </motion.div>
              ))}
            </div>
            {filteredProjects.length === 0 && (
              <div className="text-center py-12">
                <p className="text-dark-400 text-lg">
                  {isAuthenticated ? 'Henüz hiç proje eklenmemiş.' : 'Aramanızla eşleşen proje bulunamadı.'}
                </p>
              </div>
            )}
          </>
        )}
      </main>

      {/* Footer */}
      <footer className="bg-dark-900/80 backdrop-blur-xl border-t border-dark-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <p className="text-center text-dark-400">
            © {new Date().getFullYear()} AppStorage. Tüm hakları saklıdır.
          </p>
        </div>
      </footer>

      <Toaster position="top-right" />
    </div>
  );
}

export default App