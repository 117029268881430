import React, { useState } from 'react';
import { supabase } from '../lib/supabase';
import { adminUser } from '../types';
import { X } from 'lucide-react';
import toast from 'react-hot-toast';

interface AdminLoginProps {
  onLogin: () => void;
  onCancel: () => void;
}

export function AdminLogin({ onLogin, onCancel }: AdminLoginProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Clear any existing sessions first
      await supabase.auth.signOut();

      // Then sign in with Supabase
      const { data: signInData, error: signInError } = await supabase.auth.signInWithPassword({
        email,
        password
      });

      if (signInError) {
        throw signInError;
      }

      if (!signInData.user) {
        throw new Error('Kullanıcı bilgileri alınamadı');
      }

      // Check if profile exists, if not create it
      const { data: profileData, error: profileError } = await supabase
        .from('profiles')
        .select('is_approved, role')
        .eq('id', signInData.user.id)
        .single();

      if (profileError) {
        // If profile doesn't exist, create it
        if (profileError.code === 'PGRST116') {
          const isAdmin = email === 'appstoragemail@gmail.com';
          
          const { error: insertError } = await supabase
            .from('profiles')
            .insert({
              id: signInData.user.id,
              username: email,
              full_name: email === 'appstoragemail@gmail.com' ? 'Admin' : null,
              is_approved: isAdmin,
              role: isAdmin ? 'admin' : 'user'
            });
            
          if (insertError) {
            throw insertError;
          }
          
          // If not admin and not auto-approved
          if (!isAdmin) {
            await supabase.auth.signOut();
            throw new Error('Hesabınız henüz onaylanmadı. Admin onayı bekleniyor.');
          }
        } else {
          throw profileError;
        }
      } else if (!profileData.is_approved) {
        // Sign out if not approved
        await supabase.auth.signOut();
        throw new Error('Hesabınız henüz onaylanmadı. Admin onayı bekleniyor.');
      }

      toast.success('Başarıyla giriş yapıldı');
      onLogin();
    } catch (err) {
      console.error('Login error:', err);
      toast.error(err instanceof Error ? err.message : 'Giriş yapılırken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-gray-900 p-8 rounded-xl border border-gray-800 w-full max-w-md relative">
        <button
          onClick={onCancel}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-200 transition-colors"
        >
          <X className="w-5 h-5" />
        </button>

        <h2 className="text-2xl font-bold text-gray-100 mb-6">Giriş Yap</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">
              E-posta
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading}
              className="w-full bg-gray-800 border border-gray-700 rounded-lg px-4 py-2 text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent disabled:opacity-50"
              required
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-300 mb-1">
              Şifre
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
              className="w-full bg-gray-800 border border-gray-700 rounded-lg px-4 py-2 text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent disabled:opacity-50"
              required
            />
          </div>
          <div className="flex justify-end gap-4 mt-6">
            <button
              type="button"
              onClick={onCancel}
              className="px-4 py-2 text-gray-300 hover:text-gray-100 transition-colors"
              disabled={loading}
            >
              İptal
            </button>
            <button
              type="submit"
              disabled={loading}
              className="bg-blue-600 hover:bg-blue-700 text-white font-medium px-6 py-2 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? 'Giriş yapılıyor...' : 'Giriş Yap'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}