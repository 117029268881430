import { createClient } from '@supabase/supabase-js';
import type { Database } from './database.types';
import toast from 'react-hot-toast';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false,
    storage: window.localStorage,
    storageKey: 'app_storage_auth',
    flowType: 'pkce'
  }
});

// Add auth state change listener
supabase.auth.onAuthStateChange((event, session) => {
  if (event === 'SIGNED_OUT') {
    // Clear any auth-related storage
    localStorage.removeItem('app_storage_auth');
    localStorage.removeItem('supabase.auth.token');
  } else if (event === 'SIGNED_IN' && session) {
    // Ensure we have the latest session data
    localStorage.setItem('app_storage_auth', JSON.stringify(session));
  }
});

// Helper function to ensure storage bucket exists
async function ensureStorageBucket() {
  try {
    const { data: buckets } = await supabase.storage.listBuckets();
    const projectsBucket = buckets?.find(b => b.name === 'projects');

    if (!projectsBucket) {
      const { error: createError } = await supabase.storage.createBucket('projects', {
        public: true,
        fileSizeLimit: 5242880, // 5MB
        allowedMimeTypes: ['image/jpeg', 'image/png', 'image/webp']
      });

      if (createError) {
        throw createError;
      }
    }

    return true;
  } catch (error) {
    console.error('Error ensuring bucket exists:', error);
    return false;
  }
}

// Helper function to get image URL from storage
export async function uploadProjectImage(file: File): Promise<string> {
  try {
    // Validate file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      throw new Error('Dosya boyutu 5MB\'dan küçük olmalıdır');
    }

    // Validate file type
    if (!['image/jpeg', 'image/png', 'image/webp'].includes(file.type)) {
      throw new Error('Sadece JPEG, PNG ve WebP formatları desteklenmektedir');
    }

    // Check auth status before upload
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) {
      throw new Error('Görsel yüklemek için giriş yapmanız gerekmektedir');
    }

    // Ensure bucket exists
    const bucketExists = await ensureStorageBucket();
    if (!bucketExists) {
      throw new Error('Storage bucket oluşturulamadı. Lütfen daha sonra tekrar deneyin.');
    }

    const fileExt = file.type.split('/')[1];
    const uniqueId = Math.random().toString(36).substring(2);
    const fileName = `${Date.now()}_${uniqueId}.${fileExt}`;

    // Show upload progress
    toast.loading('Görsel yükleniyor...', { id: 'upload' });

    const { error: uploadError, data } = await supabase.storage
      .from('projects')
      .upload(fileName, file, {
        cacheControl: '3600',
        upsert: false
      });

    if (uploadError) {
      throw uploadError;
    }

    if (!data?.path) {
      throw new Error('Upload successful but file path is missing');
    }

    const { data: { publicUrl } } = supabase.storage
      .from('projects')
      .getPublicUrl(data.path);

    toast.success('Görsel başarıyla yüklendi', { id: 'upload' });
    return publicUrl;
  } catch (error) {
    console.error('Error uploading image:', error);
    toast.error(error instanceof Error ? error.message : 'Görsel yüklenirken bir hata oluştu', { id: 'upload' });
    throw error;
  }
}

// Helper function to check auth status
export async function checkAuthStatus(): Promise<boolean> {
  try {
    const { data: { session }, error } = await supabase.auth.getSession();
    if (error) throw error;
    return !!session;
  } catch (error) {
    console.error('Error checking auth status:', error);
    return false;
  }
}

// Helper function to handle sign out
export async function signOut() {
  try {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
    toast.success('Başarıyla çıkış yapıldı');
  } catch (error) {
    console.error('Error during sign out:', error);
    toast.error('Çıkış yapılırken bir hata oluştu');
  }
}

// Helper function to get user profile
export async function getUserProfile() {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    
    if (!user) {
      return null;
    }

    // First try to get the existing profile
    const { data, error } = await supabase
      .from('profiles')
      .select('*')
      .eq('id', user.id)
      .maybeSingle();

    // If no profile exists, create one
    if (!data && (!error || error.code === 'PGRST116')) {
      const isAdmin = user.email === 'appstoragemail@gmail.com';
      
      const { data: newProfile, error: insertError } = await supabase
        .from('profiles')
        .upsert({
          id: user.id,
          username: user.email,
          full_name: user.user_metadata.full_name || null,
          is_approved: isAdmin,
          role: isAdmin ? 'admin' : 'user'
        })
        .select()
        .single();
        
      if (insertError) {
        throw insertError;
      }

      return {
        id: newProfile.id,
        username: newProfile.username,
        fullName: newProfile.full_name,
        avatarUrl: newProfile.avatar_url,
        isApproved: newProfile.is_approved,
        role: newProfile.role as 'admin' | 'user',
        createdAt: newProfile.created_at
      };
    }

    if (error && error.code !== 'PGRST116') {
      throw error;
    }

    return data ? {
      id: data.id,
      username: data.username,
      fullName: data.full_name,
      avatarUrl: data.avatar_url,
      isApproved: data.is_approved,
      role: data.role as 'admin' | 'user',
      createdAt: data.created_at
    } : null;
  } catch (error) {
    console.error('Error getting user profile:', error);
    return null;
  }
}

// Helper function to check if user is admin
export async function isUserAdmin() {
  try {
    const profile = await getUserProfile();
    return profile?.role === 'admin';
  } catch (error) {
    console.error('Error checking if user is admin:', error);
    return false;
  }
}

// Helper function to check if user is approved
export async function isUserApproved() {
  try {
    const profile = await getUserProfile();
    return profile?.isApproved === true;
  } catch (error) {
    console.error('Error checking if user is approved:', error);
    return false;
  }
}