import React, { useState, useEffect } from 'react';
import { ExternalLink, Image as ImageIcon } from 'lucide-react';
import { motion } from 'framer-motion';
import type { Project } from '../types';

interface ProjectCardProps {
  project: Project;
}

export function ProjectCard({ project }: ProjectCardProps) {
  const [imageError, setImageError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [imageSrc, setImageSrc] = useState(project.imageUrl);

  useEffect(() => {
    const img = new Image();
    img.src = project.imageUrl;
    img.onload = () => {
      setIsLoaded(true);
      setImageSrc(project.imageUrl);
    };
    img.onerror = () => {
      setImageError(true);
      setIsLoaded(true);
    };
  }, [project.imageUrl]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      whileHover={{ scale: 1.02 }}
      className="h-full transform-gpu will-change-transform"
    >
      <a
        href={project.link}
        target="_blank"
        rel="noopener noreferrer"
        className="block h-full bg-dark-800/50 backdrop-blur-sm rounded-xl overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300 border border-dark-700/50 hover:border-primary-500/50 group"
        aria-label={`${project.title} projesini görüntüle`}
      >
        <div className="relative aspect-video overflow-hidden bg-dark-900">
          <div className="absolute inset-0 bg-gradient-to-t from-dark-900/90 via-transparent to-transparent z-10 pointer-events-none" />
          {!isLoaded && (
            <div className="absolute inset-0 flex items-center justify-center bg-dark-800 animate-pulse">
              <ImageIcon className="w-12 h-12 text-dark-600" />
            </div>
          )}
          {imageError ? (
            <div className="absolute inset-0 flex items-center justify-center">
              <ImageIcon className="w-12 h-12 text-dark-600" />
              <span className="text-dark-400 text-sm ml-2">Resim yüklenemedi</span>
            </div>
          ) : (
            <img
              src={imageSrc}
              alt={`${project.title} projesi önizleme görseli`}
              className={`w-full h-full object-cover transition-transform duration-300 ${
                isLoaded ? 'opacity-100' : 'opacity-0'
              } will-change-transform group-hover:scale-105`}
              style={{ 
                backfaceVisibility: 'hidden',
                transform: 'translateZ(0)',
                imageRendering: 'high-quality',
                transformStyle: 'preserve-3d'
              }}
              onError={() => setImageError(true)}
              loading="lazy"
              decoding="async"
              fetchPriority="high"
              sizes="(max-width: 640px) 100vw, (max-width: 1024px) 50vw, 33vw"
              crossOrigin="anonymous"
            />
          )}
        </div>
        <div className="p-6 flex flex-col flex-1">
          <h2 className="text-xl font-semibold text-white mb-2 line-clamp-1">
            {project.title}
          </h2>
          <p className="text-dark-300 mb-4 line-clamp-2">{project.description}</p>
          {project.tags && project.tags.length > 0 && (
            <div className="flex flex-wrap gap-2 mb-4">
              {project.tags.map((tag) => (
                <span
                  key={tag}
                  className="px-2.5 py-1 text-xs font-medium bg-primary-500/10 text-primary-300 rounded-full border border-primary-500/20"
                >
                  {tag}
                </span>
              ))}
            </div>
          )}
          <div className="mt-auto pt-4 flex items-center text-primary-400 group-hover:text-primary-300 transition-colors">
            <span className="text-sm font-medium">Projeyi Görüntüle</span>
            <ExternalLink className="ml-2 w-4 h-4" />
          </div>
        </div>
      </a>
    </motion.div>
  );
}